import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'
import CaseStudies from '../components/case-studies'

import RenderBlock from '../utils/render-block'
import RenderIcon from '../utils/render-icon'

class CaseStudyTemplate extends Component {

  state = {
    accordionOpen: false,
  }

  _toggleAccordion = (accordionItem) => {
    this.setState({accordionOpen: {[accordionItem]: !this.state.accordionOpen[accordionItem]} })
  }

  render() {
    let caseStudy = this.props.data.case_study
    let metaDescription = caseStudy.yoast_meta.find(x => x.name === 'description') ? caseStudy.yoast_meta.find(x => x.name === 'description').content : '';
    return (
      <>
        <SEO title={he.decode(caseStudy.yoast_title)} bodyClass={caseStudy.slug} description={metaDescription} />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/case-studies'>Case Studies</Link></li>
              <li>{he.decode(caseStudy.title)}</li>
            </ul>
          </div>
        </section>
        <section className="ciwv ciwv--columns">
          <div className="ciwv__inner container">
            <div className="ciwv__left">
              <h2 className="ciwv__title">Case Study</h2>
              <h1 className="ciwv__subtitle">{he.decode(caseStudy.title)}</h1>
            </div>
            <div className="ciwv__image case-study">
              <div className="ciwv__client">
                <ul>
                  <li>
                    <span className='label'>Client</span>
                    <span className='value'>{caseStudy.acf.client}</span>
                  </li>
                  <li>
                    <span className='label'>Start</span>
                    <span className='value'>{caseStudy.acf.start}</span>
                  </li>
                  <li>
                    <span className='label'>Value</span>
                    <span className='value'>{caseStudy.acf.value}</span>
                  </li>
                  <li>
                    <span className='label'>Complete</span>
                    <span className='value'>{caseStudy.acf.complete}</span>
                  </li>
                </ul>
              </div>
              <div className="ciwv__case-study">
                <div className="ciwv__content" dangerouslySetInnerHTML={{ __html: caseStudy.acf.content }} />
              </div>
            </div>
          </div>
        </section>
        <section className="banner-image">
          { caseStudy.acf.featured_image && <img src={caseStudy.acf.featured_image.localFile.childImageSharp.original.src} alt="FSC Group" /> }
        </section>
        <section className="services-block services-block--case-study">
          <div className="services-block__inner container">
            <div className="services-block__description">
              <Fade left distance="40px">
                <>
                  <h2 className="services-block__title">{caseStudy.acf.breakdown.title}</h2>
                  <div className="services-block__subtitle" dangerouslySetInnerHTML={{ __html: caseStudy.acf.breakdown.content }} />
                </>
              </Fade>
            </div>
            <div className="services-block__accordion">
              <Fade bottom cascade distance="40px">
                <div className="accordion">
                  { caseStudy.acf.breakdown.scope && caseStudy.acf.breakdown.scope.map((el, i) => {
                    return (
                      <div className={this.state.accordionOpen[i] ? 'accordion__item active' : 'accordion__item'} key={i}>
                        <h4 className="accordion__trigger" onClick={() => this._toggleAccordion(i)}>
                          {RenderIcon('SlimArrowRight')}
                          <span className="accordion__title" dangerouslySetInnerHTML={{ __html: el.title }} />
                          <span className="accordion__expand">
                            {this.state.accordionOpen[i] ? 'Close' : 'Expand'}
                            <span>{this.state.accordionOpen[i] ? '-' : '+'}</span>
                          </span>
                        </h4>
                        <div className="accordion__content">
                          <div className="accordion__excerpt" dangerouslySetInnerHTML={{ __html: el.content }} />
                        </div>
                      </div>
                    )
                  }) }
                </div>
              </Fade>
            </div>
          </div>
        </section>
        <CaseStudies />
      </>
    )
  }
}

export const caseStudyQuery = graphql`
  query ($id: String!) {
    case_study: wordpressWpCaseStudy(id: {eq: $id}) {
      title
      slug
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      acf {
        subtitle
        content
        client
        value
        start
        complete
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        breakdown {
          title
          content
          scope {
            title
            content
          }
        }
      }
    }
  }
`

export default CaseStudyTemplate